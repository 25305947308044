import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { onDocumentProfileFetchData } from '../../actions/actions';
import { Link } from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import Select from 'react-select';
import OrdersInfo from './orders-info';
import LoggedChanges from './logged-changes';
import SlideModal from '../common/SlideModal';
import SimpleForm from "./../forms/form";
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import Form from "../redux-forms/form";
import { Button, Tooltip, Table, Tabs, Card, Col, Row, Menu, Dropdown } from 'antd';
import { ExportOutlined, EditOutlined, FilePdfOutlined, ShopOutlined, WalletOutlined, CarOutlined, FileAddOutlined, CopyOutlined, SendOutlined, LinkOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class DealProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template: false,
            active: 'deal',
            showAccountModal: false,
            showAddressModal: false,
            showBillingModal: false,
            formId: false, 
            dealTemplateCc: null
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.update = this.update.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/deal/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ template: this.props.salesProfileInformation.invoiceProfileInfo && this.props.salesProfileInformation.invoiceProfileInfo.templates.length ? this.props.salesProfileInformation.invoiceProfileInfo.templates[0].value : null })
        });
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.setState({ active: localStorage.getItem('activeDealProfileTab') ? localStorage.getItem('activeDealProfileTab') : 'deal' });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.update();
        }
    }

    update(modal) {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/deal/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ [modal]: false });
        });;
    }

    handleTemplateChange(e) {
        this.setState({ template: e.value });
    }

    changeTab(tab) {
        localStorage.setItem('activeDealProfileTab', tab);

        this.setState({ active: tab });
    }

    openModal(modal) {
        this.setState({ [modal]: true });
    }

    setDataToDeal(type, id, dealId) {
        let addVal = {};
        let jsonData = [];
        addVal[type] = id;
        jsonData.push(JSON.stringify(addVal));

        client.post('/' + this.props.match.params.db + '/api/online/deal/' + dealId + '/set/account/data', jsonData)
            .then(response => response.data)
            .then(response => {
                if (response['success']) {
                    this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/deal/' + this.props.match.params.id + '/profile-info');
                    showAToast(response.success, 'baseSuccess', 'success');
                } else {
                    showAToast(response.errors, 'baseError', 'error');
                }
            })
    }

    onCopyLink(link) {
        navigator.clipboard.writeText(window.location.origin + link);
        showAToast(plainTranslate(this.props.settings.locale, 'Deal link copied!'), 'succSettings', 'success');
    }

    sentEmailTemplate = () => {
        this.setState({emailPopup: true});
    }

    render() {
        let docNo = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.docNo : '';
        let currencySign = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.currency : null;
        let stages = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.stages : '';
        let deal = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.deal : '';
        let tableData = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.dealLines : '';
        let templates = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.templates : [];
        let users = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.users : [];
        let sales = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.sales : [];
        let dealSalePercentage = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.dealSalePercentage : 0;
        let roles = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.roles : {};
        let changes = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.changes : [];
        let isOnlineDeal = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.isOnlineDeal : false;
        let accountForms = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.accountForms : false;
        let ownerAccount = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.ownerAccount : false;

        const lineColumns = [
            {
                'dataIndex': 'itCode',
                'title': plainTranslate(this.props.settings.locale, 'Item code'),
                'width': 10,
            },
            {
                'dataIndex': 'itTitle',
                'title': plainTranslate(this.props.settings.locale, 'Item'),
                'width': 30,
            },
            {
                'dataIndex': 'productName',
                'title': plainTranslate(this.props.settings.locale, 'Product name'),
                'width': 30,
            },
            {
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Quantity'),
                'width': 20,
            },
            {
                'dataIndex': 'currName',
                'title': plainTranslate(this.props.settings.locale, 'Currency'),
                'width': 20,
            },
            {
                'dataIndex': 'priceUnitOriginal',
                'title': plainTranslate(this.props.settings.locale, 'Price'),
                'width': 20,
            },
            {
                'dataIndex': 'amountTotalOriginal',
                'title': plainTranslate(this.props.settings.locale, 'Total'),
                'width': 20,
            },
        ];

        if (this.props.salesProfileInformation.loading && !this.props.salesProfileInformation.invoiceProfileInfo) {
            return <FormLoader />
        }

        let menuOptions = null;
        if (accountForms && accountForms.length) {
            menuOptions = (
                <Menu>
                    {accountForms.map((option, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ showAccountModal: true, formId: option.value })}>
                                <Translate locale={this.props.settings.locale} value={option.label} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        let publicDealTemplates = [];
        if (templates) {
            publicDealTemplates = (
                <Menu>
                    {templates.map((option, i) => {
                        return <Menu.Item key={i}>
                            <span style={{ cursor: 'pointer' }} onClick={() => this.onCopyLink("/" + this.props.match.params.db + "/public/deals/" + deal.cc + '/template/' + option.cc)}>{plainTranslate(this.props.settings.locale, option.label)}</span>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return (
            <div className="invoice-profile item-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12" style={{ display: "flex", alignItems: "center" }}>
                        {!this.props.gridPopup &&
                            <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                                <Link to={"/" + this.props.settings.db + "/deals-v2"}>
                                    <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Deals')}
                                </Link>
                            </div>
                        }
                        {roles && roles.hasView === true && this.props.gridPopup &&
                            <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                                <a style={{ marginRight: 20, float: "right" }} href={"/" + this.props.settings.db + '/deal/' + this.props.match.params.id + '/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{ fontSize: 15, marginLeft: 5 }} /></a>
                            </div>
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation
                                document={deal}
                                docNo={docNo}
                                stages={stages}
                                currency={currencySign}
                                update={this.update}
                                asyncUrl={'/' + this.props.settings.db + '/api/users'}
                                changeStageUrl={'/' + this.props.settings.db + '/api/deals/'}
                                users={users}
                            />
                            <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter" />
                            <div className="account-profile-other-information">
                                <OrdersInfo orders={sales} />
                                {/* <div class="single-chart">
                                    <h4 className="text-center">{plainTranslate(this.props.settings.locale, 'Sold')}</h4>
                                    <svg viewBox="0 0 36 36" class="circular-chart orange">
                                        <path class="circle-bg"
                                            d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path class="circle"
                                            stroke-dasharray={`${dealSalePercentage}, 100`}
                                            d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <text x="18" y="20.35" class="percentage">{dealSalePercentage}%</text>
                                    </svg>
                                </div> */}
                                <LoggedChanges data={changes} title={'Logged changes'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                            {window.innerWidth > 780 &&
                                <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                    {roles && roles.hasView === true &&
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                            <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                <Link to={"/" + this.props.match.params.db + "/deals/" + this.props.match.params.id + '/edit'}>
                                                    <EditOutlined />
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    }
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Link to={'/' + this.props.settings.db + '/deals/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf'}>
                                                <FilePdfOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Send deal')}>
                                        <Button type="default" onClick={() => this.sentEmailTemplate()} ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <SendOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Copy public deal link')}>
                                        <Dropdown overlay={publicDealTemplates}>
                                            <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                <LinkOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </div>
                            }
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter sale-tabs">
                                <button className={this.state.active == 'deal' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('deal')}>
                                    {plainTranslate(this.props.settings.locale, 'Deal')}
                                </button>
                                {isOnlineDeal &&
                                    <button className={this.state.active == 'isOnlineDeal' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('onlineDeal')}>
                                        {plainTranslate(this.props.settings.locale, 'Online Deal data')}
                                    </button>
                                }
                            </div>
                            <div className="col-md-12 col-xs-12">
                                {this.state.template && this.state.active === 'deal' &&
                                    <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                        <div className="row pull-right template-select">
                                            <Select
                                                onChange={this.handleTemplateChange}
                                                onBlurResetsInput={false}
                                                onCloseResetsInput={false}
                                                menuPlacement={"auto"}
                                                options={templates}
                                                value={templates.find(o => o.value === this.state.template)}
                                            />
                                        </div>
                                        <div className="row">
                                            <embed src={'/' + this.props.settings.db + '/deals/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf?token=' + localStorage.jwtToken} type="application/pdf" height="775px" width="100%" />
                                        </div>
                                    </div>
                                }
                                {this.state.active === 'deal' && !this.state.template &&
                                    <div className="col-lg-12 col-md-12 col-xs-12 actions">
                                        <span>
                                            <Link to={"/" + this.props.settings.db + "/administration/templates/documents"}>
                                                {plainTranslate(this.props.settings.locale, 'Create deal template')}
                                            </Link>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="col-md-12 col-xs-12 no-gutter">
                                {this.state.active === 'onlineDeal' && isOnlineDeal &&
                                    <div>
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            <Row align="top" type="flex">
                                                <Col md={6} xs={24} className="onlineOrder-status delivery">
                                                    <div className="container-status delivery-bg">
                                                        <h4 style={{ display: "inline-flex" }}><CarOutlined theme="twoTone" twoToneColor="#52c41a" style={{ marginRight: 5 }} /> {plainTranslate(this.props.settings.locale, 'Delivery method')}</h4>
                                                    </div>
                                                    <div className="container-status">
                                                        <h4><strong>{deal && deal.deliveryMethodName ? deal.deliveryMethodName : plainTranslate(this.props.settings.locale, 'Delivery method is not choosen')}</strong></h4>
                                                        <h5><strong>{deal && deal.custom_delivery_shop ? deal.custom_delivery_shop : ''}</strong></h5>
                                                    </div>
                                                </Col>
                                                <Col span={1} />
                                                <Col md={6} xs={24} className="onlineOrder-status payment">
                                                    <div className="container-status payment-bg">
                                                        <h4 style={{ display: "inline-flex" }}> <WalletOutlined theme="twoTone" twoToneColor="#f39c12" style={{ marginRight: 5 }} /> {plainTranslate(this.props.settings.locale, 'Payment method')}</h4>
                                                    </div>
                                                    <div className="container-status">
                                                        <h4><strong>{deal && deal.paymentMethodName ? deal.paymentMethodName : plainTranslate(this.props.settings.locale, 'Payment method is not choosen')}</strong></h4>
                                                    </div>
                                                </Col>
                                                <Col span={1} />
                                                <Col md={10} className="onlineOrder-customerData">
                                                    {deal && (deal.ownerName || deal.accName) &&
                                                        <div className="onlineOrder-right-side">
                                                            <Card title={plainTranslate(this.props.settings.locale, 'Customer Details')} extra={deal.accName || ownerAccount.value ?
                                                                <span>
                                                                    <p>
                                                                        {deal.accId ?
                                                                            <Link to={'/' + this.props.match.params.db + '/account/' + deal.accId + '/profile'}>
                                                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                                                                                {plainTranslate(this.props.settings.locale, 'Go to ')} {deal.accName}
                                                                            </Link>
                                                                            :
                                                                            <Link to={'/' + this.props.match.params.db + '/account/' + ownerAccount.value + '/profile'}>
                                                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                                                                                {plainTranslate(this.props.settings.locale, 'Go to ')} {ownerAccount.label}
                                                                            </Link>
                                                                        }
                                                                    </p>
                                                                </span>
                                                                :
                                                                <>
                                                                    {menuOptions &&
                                                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                                            <Dropdown overlay={menuOptions}>
                                                                                <Link to="#" data-toggle="dropdown" title="New Account">
                                                                                    <i className="fa fa-user-circle"></i> {plainTranslate(this.props.settings.locale, 'Create account')}
                                                                                </Link>
                                                                            </Dropdown>
                                                                        </Button>
                                                                    }
                                                                </>
                                                            }>
                                                                <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong>{deal.ownerName || deal.accName} </strong></p>
                                                                <p>{plainTranslate(this.props.settings.locale, 'Email')}: <strong>{deal.ownerId ? deal.ownerId.label : deal.accEmail}</strong></p>
                                                                <p>{plainTranslate(this.props.settings.locale, 'Phone')}: <strong>{deal.accPhone ? deal.accPhone : ''}</strong></p>
                                                                {!deal.accName && ownerAccount.value &&
                                                                    <span onClick={() => this.setDataToDeal('account', ownerAccount.value, deal.id)}><strong><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set account to deal')}</strong></span>
                                                                }
                                                            </Card>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            <Tabs defaultActiveKey="order-data">
                                                <TabPane key="order-data" tab={
                                                    <span>
                                                        <ShopOutlined />
                                                        {plainTranslate(this.props.settings.locale, 'Order data')}
                                                    </span>
                                                }>
                                                    <div>
                                                        <Table rowKey={record => record.id} columns={lineColumns} dataSource={deal ? deal.lines : []} scroll={{ x: 1500, y: 300 }} />
                                                    </div>
                                                </TabPane>
                                                {deal && deal.accountAddresses &&
                                                    <TabPane key="shipping-address" tab={
                                                        <span>
                                                            <CarOutlined />
                                                            {plainTranslate(this.props.settings.locale, 'Shipping Address')}
                                                        </span>
                                                    }>
                                                        <div className="onlineOrder-right-side no-gutter">
                                                            <Card title={plainTranslate(this.props.settings.locale, 'Shipping Address')}
                                                                extra={!deal.addrId && <span onClick={() => this.openModal('showAddressModal')}>
                                                                    <FileAddOutlined /> {plainTranslate(this.props.settings.locale, 'Create address')}
                                                                </span>}>
                                                                {deal.accountAddresses &&
                                                                    <h3>{plainTranslate(this.props.settings.locale, 'Account addreses')}</h3>
                                                                }
                                                                {deal.accountAddresses && deal.accountAddresses.map((address) =>
                                                                    <Card title={plainTranslate(this.props.settings.locale, address.name)}
                                                                        extra={!deal.addrId && <span onClick={() => this.setDataToDeal('address', address.id, deal.id)}
                                                                        ><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set address to deal')}</span>}>
                                                                        {address.name &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Name')}:<strong> {address.name}</strong></p>}
                                                                        {address.country &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Country')}:<strong> {address.country}</strong></p>}
                                                                        {address.district &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'District')}:<strong> {address.district}</strong></p>}
                                                                        {address.city &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'City')}:<strong> {address.city}</strong></p>}
                                                                        {address.postCode &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Code')}:<strong> {address.postCode}</strong></p>}
                                                                        {address.phone &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Phone')}:<strong> {address.phone}</strong></p>}
                                                                        {address.email &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Email')}:<strong> {address.email}</strong></p>}
                                                                        {address.addressLine1 &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Address')}:<strong> {address.addressLine1}</strong></p>}
                                                                        {address.addressLine2 &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Address')}:<strong> {address.addressLine2}</strong></p>}
                                                                    </Card>
                                                                )
                                                                }
                                                            </Card>
                                                        </div>
                                                    </TabPane>
                                                }
                                                {deal && deal.accountBillingDatas &&
                                                    <TabPane key="billing-data" tab={
                                                        <span>
                                                            <WalletOutlined />
                                                            {plainTranslate(this.props.settings.locale, 'Billing data')}
                                                        </span>
                                                    } >
                                                        <div className="onlineOrder-right-side">
                                                            <Card title={plainTranslate(this.props.settings.locale, 'Billing data')}
                                                                extra={!deal.custId ? <span onClick={() => this.openModal('showBillingModal')}>
                                                                    <FileAddOutlined />
                                                                    {plainTranslate(this.props.settings.locale, 'Create billing data')}</span> : ''}>
                                                                {deal.accountBillingDatas &&
                                                                    <h3>{plainTranslate(this.props.settings.locale, 'Account billing data')}</h3>
                                                                }
                                                                {deal.accountBillingDatas && deal.accountBillingDatas.map((billing) =>
                                                                    <Card title={plainTranslate(this.props.settings.locale, billing.name)}
                                                                        extra={!deal.custId && <span onClick={() => this.setDataToDeal('billing', billing.id, deal.id)}
                                                                        >
                                                                            <CopyOutlined />
                                                                            {plainTranslate(this.props.settings.locale, 'Set billing to deal')}</span>
                                                                        }>
                                                                        {billing.uic &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'VAT')}: <strong> {billing.uic}</strong></p>}
                                                                        {billing.vat &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'UIC')}: <strong> {billing.vat}</strong></p>}
                                                                        {billing.responsiblePerson &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Responsible person')}: <strong> {billing.responsiblePerson}</strong></p>}
                                                                        {billing.fullAddress &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Full Address')}: <strong> {billing.fullAddress}</strong></p>}
                                                                        {billing.address1 &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}: <strong> {billing.address1}</strong></p>}
                                                                        {billing.address2 &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}: <strong> {billing.address2}</strong></p>}
                                                                        {billing.city &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {billing.city}</strong></p>}
                                                                        {billing.country &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {billing.country}</strong></p>}
                                                                        {billing.postCode &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Post code')}: <strong> {billing.postCode}</strong></p>}
                                                                        {billing.label &&
                                                                            <p>{plainTranslate(this.props.settings.locale, 'Label')}: <strong> {billing.label}</strong></p>}
                                                                    </Card>
                                                                )
                                                                }
                                                            </Card>
                                                        </div>
                                                    </TabPane>
                                                }
                                            </Tabs>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {this.state.showAccountModal &&
                            <SlideModal onClose={() => this.setState({ showAccountModal: false })} title={plainTranslate(this.props.settings.locale, 'Account')}>
                                <section className="newPanel" ref={this.setWrapperRef}>
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                formRoute={this.props.match.params.db + '/api/account/form/'}
                                                saveRoute={this.props.match.params.db + '/api/accounts/new'}
                                                popup={true}
                                                updateParent={() => this.update('showAccountModal')}
                                                onCancel={() => this.setState({ showAccountModal: false })}
                                                match={{ params: { id: this.state.formId } }}
                                                refs={deal.ownerId.value}
                                                cartSaleId={deal.id}
                                                form={'initializeFromState2'}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                        }
                        {this.state.emailPopup === true &&
                            <SlideModal onClose={() => this.setState({emailPopup: false})} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                            <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/message-email/'}
                                        saveRoute={this.props.match.params.db + '/api/message-email/new'}
                                        popup={true}
                                        onSuccessfulSave={() => this.setState({ emailPopup: false })}
                                        onCancel={() => this.setState({ emailPopup: false })}
                                        type={JSON.stringify({"dealCc": deal.cc})}
                                        form={'initializeFromState'}
                                        match={{ params: { id: 0 } }}
                                        isNew={true}
                                        sendButton={true}
                                    />
                                </div>
                            </div>
                            </section>
                        </SlideModal>
                        }
                        {this.state.showAddressModal &&
                            <SlideModal onClose={() => this.setState({ showAddressModal: false })} title={plainTranslate(this.props.settings.locale, 'Address')}>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                formRoute={this.props.match.params.db + '/api/address/'}
                                                saveRoute={this.props.match.params.db + '/api/address?accountId=' + deal.accId}
                                                updateParent={() => this.update('showAddressModal')}
                                                popup={true}
                                                isNew={true}
                                                onCancel={() => this.setState({ showAddressModal: false })}
                                                match={{ params: { id: 0 } }}
                                                refs={deal.accId}
                                                cartSaleId={deal.id}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                        }
                        {this.state.showBillingModal &&
                            <SlideModal onClose={() => this.setState({ showBillingModal: false })} title={plainTranslate(this.props.settings.locale, 'Billing data')}>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                formRoute={this.props.match.params.db + '/api/billing-datas/'}
                                                saveRoute={this.props.match.params.db + '/api/billing-datas'}
                                                updateParent={() => this.update('showBillingModal')}
                                                popup={true}
                                                isNew={true}
                                                onCancel={() => this.setState({ showBillingModal: false })}
                                                match={{ params: { id: 0 } }}
                                                refs={deal.accId}
                                                cartSaleId={deal.id}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                        }
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    salesProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealProfile);
